import axios from "axios";
import * as Msg from "../components/ui/Toastify/Toastify";
import {AUTH_REFRESH_TOKEN} from "../constants/ApiEndPoint";

const request = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const handleError = async error => {
  const originalConfig = error.config;
  if (error.response) {
    if (error.response.status === 401 && !originalConfig._retry) {
      let userLocal = sessionStorage.getItem('user');
      if (userLocal) {
        userLocal = JSON.parse(userLocal);
        originalConfig._retry = true;
        try {
          const rs = await request.post(AUTH_REFRESH_TOKEN, {
            refresh_token: userLocal.refresh_token,
          });
          if (rs.data.status) {
            userLocal.gem_token = rs.data.data.gem_token;
            sessionStorage.setItem('user', JSON.stringify(userLocal));
            return request(originalConfig);
          } else {
            sessionStorage.removeItem('user');
          }
        } catch (_error) {
          sessionStorage.removeItem('user')
          window.location.reload();
          return Promise.reject(_error);
        }
      }
    } else if (error.response.status === 401) {
      sessionStorage.removeItem('user')
      return Promise.reject(error.response.data || error);
    }
  }
  return Promise.reject(error);
};

const handleSuccess = async res => {
  return res;
};

const handleRequest = async config => {
  let userLocal = sessionStorage.getItem('user');
  const requestConfig = config;
  if (userLocal) {
    userLocal = JSON.parse(userLocal);
    const {headers} = config;
    requestConfig.headers = {...headers, 'gem-token': userLocal.gem_token};
  }
  return requestConfig;
};

request.interceptors.response.use(handleSuccess, handleError);
request.interceptors.request.use(handleRequest, error => Promise.reject(error));

export const CallApiBackend = async (data = {}, url, method = 'GET', header = {}) => {
  try {
    return await request(Object.assign({
        url: url,
        method: method,
        header: header,
      },
      method.toUpperCase() === 'GET' ? {params: data} : {data: data}
    ))
  } catch (error) {
    console.log(error)
    if (error.response.status === 403 || !error.response) {
      sessionStorage.removeItem('user');
      Msg.notifyError("Vui lòng đăng nhập lại!");
    }
    return error;
  }
};
