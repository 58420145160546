import React, { useCallback, useEffect, useRef } from "react";

function Draggable({ children, style, className, onDragStart, onDragEnd }) {
  const dragRef = useRef(null);
  let isMouseDown = false;
  let offset = [0, 0];

  const onMouseDown = (e) => {
    isMouseDown = true;
    const dragDiv = dragRef.current;
    if (!dragDiv) return;

    const isTouch = /touch/g.test(e.type);
    const x = isTouch ? e.touches[0].clientX : e.clientX;
    const y = isTouch ? e.touches[0].clientY : e.clientY;

    offset = [dragDiv.offsetLeft - x, dragDiv.offsetTop - y];

    if (onDragStart) {
      const rect = dragDiv.getBoundingClientRect();
      onDragStart(rect);
    }

    dragDiv.addEventListener("mouseup", onMouseUp, true);
    dragDiv.addEventListener("touchend", onMouseUp, true);

    document.addEventListener("contextmenu", onContextMenu, false);
    document.addEventListener("touchmove", onMouseMove, true);
    document.addEventListener("mousemove", onMouseMove, true);
  };

  const onMouseUp = (e) => {

    isMouseDown = false;
    if (!isMouseDown && onDragEnd) {
      const rect = dragRef.current.getBoundingClientRect();
      onDragEnd(rect);
    }

    document.removeEventListener("touchmove", onMouseMove, true);
    document.removeEventListener("mousemove", onMouseMove, true);
    document.removeEventListener("contextmenu", onContextMenu, false);
  };

  const onMouseMove = useCallback((e) => {
    const isTouch = /touch/g.test(e.type);
    if (!isTouch) {
      e.preventDefault();
    }

    if (isMouseDown && dragRef.current) {
      let x = isTouch ? e.touches[0].clientX : e.clientX;
      let y = isTouch ? e.touches[0].clientY : e.clientY;
      let left = x + offset[0] < 0 ? 0 : (x + offset[0] > window.innerWidth ? window.innerWidth-44-10 : x+offset[0]);
      let top = y + offset[1] < 60 ? 60 : (y + offset[1] > (window.innerHeight-dragRef.current.offsetHeight) ? (window.innerHeight -dragRef.current.offsetHeight) : y+offset[1]);
      dragRef.current.style.left = left + "px";
      dragRef.current.style.top = top + "px";
    }
  }, []);

  const onContextMenu = () => {
    document.removeEventListener("mousemove", onMouseMove, true);
    document.removeEventListener("touchmove", onMouseMove, true);
  };

  useEffect(() => {
    const dragDiv = dragRef.current;

    dragDiv.addEventListener("touchstart", onMouseDown, true);
    dragDiv.addEventListener("mousedown", onMouseDown, true);

    return () => {
      dragDiv.removeEventListener("mousedown", onMouseDown, true);
      dragDiv.removeEventListener("mouseup", onMouseUp, true);
      document.removeEventListener("mousemove", onMouseMove, true);

      dragDiv.removeEventListener("touchstart", onMouseDown, true);
      dragDiv.removeEventListener("touchend", onMouseUp, true);
      document.removeEventListener("touchmove", onMouseMove, true);

      document.removeEventListener("contextmenu", onContextMenu, false);
    };
  }, []);

  return React.createElement(
    "div",
    {
      ref: dragRef,
      className: className || "drag-react",
      style: Object.assign({ position: "fixed", cursor: "move" }, style),
    },
    children
  );
}

export { Draggable };
