const ValidatePhone = (phoneString, emptyCheck = false) => {
  let status = true;
  let msg = "";
  if (!phoneString || (phoneString.length === 0 && emptyCheck === false)) {
      return { status, msg };
    }
  // const regexPhoneNumber = /^(84|0)\d{9}$/;

  // if (phoneString.match(regexPhoneNumber) === null) {
  //   status = false;
    if(phoneString.length!== 10) {
        status=false
        msg = "Số điện thoại phải có 10 chữ số"
    }
  //   else {

  //       msg = "Số điện thoại phải bắt đầu bằng 0 hoặc 84";
  //   }
  // }

  return { status, msg };
};

module.exports = { ValidatePhone };
