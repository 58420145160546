import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './style.css';

export const notifyInfo = (msg, delay = 3000) => {
    toast.success(msg, {
        position: 'top-center',
        autoClose: delay,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        icon: true,
        className: 'Toastify__toast--style',
        closeButton: false,
        style: {
            marginBottom: '10px'
        }
    });
};

export const notifyError = (msg, delay = 3000) => {
    toast.error(msg, {
        position: 'top-center',
        autoClose: delay,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        icon: true,
        className: 'Toastify__toast--style',
        closeButton: false,
        style: {
            marginTop: '10px'
        }
    });
};