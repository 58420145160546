import './style.css';
import {Link, useNavigate} from "react-router-dom";
import arrow_left from './../../assets/images/arrow_left.png';
import home from './../../assets/images/home.png';
import {useContext} from "react";
import {UserAuth} from "../../context/AuthContext";
import option from "../../assets/images/personal3.png";


export const TopBar = ({
  isBack = true,
  title = '',
  goCart = false,
  goOption = false,
  goHome = true,
  onClose = null,
  supplier = null
}) => {
  let navigate = useNavigate();
  const {userData} = useContext(UserAuth);
  const pathName = window.location.pathname;
  const handleBack = () => {
    if (onClose !== null) {
      onClose()
    } else {
      if (pathName !== '/' && pathName !== '/mbapp') navigate(-1)
    }
  }
  return (
    <header>
      <nav>
        <div className={'nav-item'}>
          {
            isBack && pathName !== '/' && pathName !== '/mbapp' ?
              <p onClick={handleBack}>
                <img src={arrow_left} alt="" height={17}/>
              </p>
              : ''
          }
        </div>
        <div className={'nav-item'}>
          <p className={'page-name'}>
            {title}
          </p>
        </div>
        <div className={'nav-item'} style={{alignItems: 'end'}}>
          {
            goCart ?
              <Link
                to={'/gio-hang'}
                style={{position: 'relative'}}
                state={{
                  supplier_id: supplier,
                }}
              >
                <img src="https://cdn.gemretail.vn/images/icon/icon_shoping_cart.png" width={20} height={20} alt="" style={{filter: "brightness(0) invert(1)"}}/>
                {
                  userData?.cart_count?.[supplier] && userData.cart_count[supplier] > 0 ?
                    <span style={styles.badgeNoti}>{userData?.cart_count[supplier]}</span> : ''
                }
              </Link>
              : ''
          }
          {
            goOption ?
              <Link to={'/history'}>
                <img src={option} width={17} height={16} alt=""/>
              </Link>
              : ''
          }
          {
            goHome && pathName !== '/' && pathName !== '/mbapp' ?
              (
                <Link to={'/'}>
                  <img src={home} width={20} height={20} alt=""/>
                </Link>
              )
              : ''
          }
        </div>
      </nav>
    </header>
  )
}


const styles = {

  badgeNoti: {
    position: 'absolute',
    top: '-30%',
    right: '-50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '14px',
    height: '14px',
    backgroundColor: 'red',
    color: 'white',
    fontSize: '12px',
    borderRadius: '50%',
  }
};
  