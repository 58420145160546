import React, {lazy, Suspense} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";

import {ProgramRules} from "../pages/ProgramRules/ProgramRules";
import {ScreenPurchaseInformation} from "../pages/Order/OrderScreen";
import {AuthContext} from "../context/AuthContext";
import {ToastContainer} from "react-toastify";
import {EditOrderScreen} from "../pages/Order/EditOrder";
import NotFound from "../pages/NotFound/NotFound";
import {LoadingCustom} from "../components/Loading/LoadingCustom";

const Home = lazy(() => import("./../pages/Home/Home"));
const HomeFlashSale = lazy(() => import("./../pages/HomeFlashSale/HomeFlashSaleScreen"));
const Detail = lazy(() => import("../pages/ProductDetail/Screen"));
const History = lazy(() => import("./../pages/Histories/ScreenHistory"));
const HistoryDetail = lazy(() => import("./../pages/Histories/ScreenHistoryDetail"));
const GioHang = lazy(() => import("./../pages/GioHang/GioHang"));
const DanhGiaSanPham = lazy(() => import("./../pages/DanhGiaSanPham/DanhGiaSanPham"));
const ReviewSuccess = lazy(() => import("./../pages/DanhGiaSanPham/DanhGiaSuccess"));
const ShowReview = lazy(() => import("./../pages/DanhGiaSanPham/ShowReview"));
const EditReview = lazy(() => import("./../pages/DanhGiaSanPham/EditReview"));
const Evaluate = lazy(() => import("../pages/Evaluate/EvaluateDetail"));


export default function RoutesMain() {
  return (
    <BrowserRouter>
      <AuthContext>
        <Routes>
          <Route
            exact
            path={'/mbapp'}
            element={<Suspense fallback={<LoadingCustom />}><HomeFlashSale/></Suspense>}
          />
          <Route
            exact
            path={'/'}
            element={<Suspense fallback={<LoadingCustom/>}><HomeFlashSale/></Suspense>}
          />
          <Route
            exact
            path={'/home/:supplier_id'}
            element={<Suspense fallback={<LoadingCustom/>}><Home/></Suspense>}
          />
          <Route
            exact
            path={'/the-le-chuong-trinh'}
            element={<ProgramRules/>}
          />
          <Route
            exact
            path={'/product-detail/:product_id'}
            element={<Suspense fallback={<LoadingCustom/>}><Detail/></Suspense>}
          />
          <>
            <Route
              exact
              path={'/order/payment'}
              element={<ScreenPurchaseInformation/>}
            />
            <Route
              exact
              path={'/order/:order_id/show-review'}
              element={<Suspense fallback={<LoadingCustom/>}><ShowReview/></Suspense>}
            />
            <Route
              exact
              path={'/order/edit/:order_id'}
              element={<Suspense fallback={<LoadingCustom/>}><EditOrderScreen/></Suspense>}
            />
            <Route
              exact
              path={'/gio-hang'}
              element={<Suspense fallback={<LoadingCustom/>}><GioHang/></Suspense>}
            />

            <Route
              exact
              path={'/order/review/:order_id'}
              element={<Suspense fallback={<LoadingCustom/>}><DanhGiaSanPham/></Suspense>}
            />

            <Route
              exact
              path={'/order/review-cuccess/:order_id'}
              element={<Suspense fallback={<LoadingCustom/>}><ReviewSuccess/></Suspense>}
            />
            <Route
              exact
              path={'/review/edit/:order_id'}
              element={<Suspense fallback={<LoadingCustom/>}><EditReview/></Suspense>}
            />

            <Route
              exact
              path={'/product/:product_id/evaluate'}
              element={<Suspense fallback={<LoadingCustom/>}><Evaluate/></Suspense>}
            />
            <Route
              exact
              path={'/history'}
              element={<Suspense fallback={<LoadingCustom/>}><History/></Suspense>}
            />
            <Route
              exact
              path={'/history/detail/:order_id'}
              element={<Suspense fallback={<LoadingCustom/>}><HistoryDetail/></Suspense>}
            />
          </>
          <Route
            exact
            path={'*'}

            element={<NotFound/>}
          />
        </Routes>
      </AuthContext>
      <ToastContainer limit={5}/>
    </BrowserRouter>
  )
}
