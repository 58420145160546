import {CallApiBackend} from "../utils/CallApiBackend";
import {
  ChiTietDanhGia,
  ChiTietDonHang,
  DanhSachDanhGiaTheoDonHang, DanhSachTheThanhToan,
  KiemTraDonHang,
  ThemGioHang
} from "../constants/ApiEndPoint";

export const getOrderDetailRequest = (order_id) => {
  return CallApiBackend({order_id: order_id}, ChiTietDonHang);
}
export const getDetailReviewRequest = (order_id) => {
  return CallApiBackend({review_id: order_id}, ChiTietDanhGia);
}
export const getDetailReviewByOrderRequest = (order_id) => {
  return CallApiBackend({order_id: order_id}, DanhSachDanhGiaTheoDonHang);
}
export const submitRateOrderRequest = (formData) => {
  return CallApiBackend(formData, '/review/add', 'POST', {'Content-Type': 'application/json'});
}
export const submitEditRateOrderRequest = (formData) => {
  return CallApiBackend(formData, '/review/edit', 'POST', {'Content-Type': 'application/json'});
}

export const addToCartRequest = (data) => {
  return CallApiBackend(data, ThemGioHang, "POST");
}
export const getListCardRequest = (data) => {
  return CallApiBackend(data, DanhSachTheThanhToan, "POST");
}

export const checkOrderRequest = (data) => {
  return CallApiBackend(data, KiemTraDonHang, "POST");
}