import React from "react";
import {BoxShadow} from "../BoxShadow/BoxShadow";
import {Col, Image, Row, Space, Typography} from "antd";
import {formatPrice} from "../../utils/formatPrice";
import {useNavigate} from "react-router-dom";

const {Text} = Typography;
const ItemProductList = ({item, numberIndex = 12, mode = 1, showMore = 0}) => {
  const navigate = useNavigate();
  return (
    <>
      {item && (
        <BoxShadow className="text-gray-800 relative h-full item-product">
          {
            !!numberIndex && (
              <div
                className="absolute top-0 left-0 z-10 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow rounded-full w-7 h-7 text-center leading-7 text-sm font-bold    ">
                {numberIndex}
              </div>
            )
          }
          <Row
            className="py-2 px-2.5 h-full"
            style={{display: "flex"}}
            gutter={0}
            wrap={false}
            justify="space-between"
            align="middle"
          >
            <Col span={6} className="flex justify-center">
              <Image
                src={item.icon || item.product_icon}
                preview={false}
                className={"w-full max-h-[120px] object-contain"}
              />
            </Col>
            <Col span={17} className="min-h-[100px]">
              <Space direction="vertical" className="justify-between" align="start" size={mode === 1 ? 1 : 14}>
                <Text
                  className="text-base font-semibold line-clamp-2 min-h-[1.5lh] leading-5">{item.product_name}</Text>
                {
                  mode === 1 && item?.status && (
                    <>
                      <div className="mt-1 flex flex-col">
                        <Text className="text-sm text-gray-500" delete>{formatPrice(item.listed_price)} VND</Text>
                        <Text className="text-base font-bold text-red-600">{formatPrice(item.sale_price)} VND</Text>
                      </div>
                      <Row justify="start" wrap={false} align="top" gutter={0} className="mt-1 gap-2">
                        <Col
                          style={{background: item.status.color || '#ccc'}}
                          className={`text-white py-[3px] font-semibold text-10  rounded-full px-3 uppercase`}>
                          {item.status.title}
                        </Col>
                        {
                          item.status.value === 10004 && (item.is_reviewed || item.can_add_review) && !item.is_hide_review &&
                          <Col
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(item.can_add_review ? `/order/review/${item.order_id}` :
                                `/order/${item.order_id}/show-review`)
                            }}
                            className={`text-white py-[3px] font-semibold text-10 bg-[#FFA900] rounded-full px-3 uppercase`}
                          >
                            {item.can_add_review ? "Đánh giá sản phẩm" : "Xem đánh giá"}
                          </Col>
                        }
                      </Row>
                      {!!showMore && (
                        <Text className="block w-full whitespace-nowrap text-right text-10 text-blue-500 mt-1">
                          Xem thêm {showMore} sản phẩm >>
                        </Text>
                      )}
                    </>
                  )
                }
                {
                  mode === 2 && (
                    <>
                      <Row justify="space-between" wrap={false} align="middle" className="w-full">
                        <Text className="block w-full whitespace-nowrap  text-sm">
                          Phân loại: {item.classify?.map(({value}) => value).join(" | ")}
                        </Text>
                        <Text className="block w-full whitespace-nowrap text-right text-sm pr-2">
                          x{item.qty}
                        </Text>
                      </Row>
                      <Row justify="end" align="bottom" className="gap-2">
                        <Text className="text-sm leading-3 text-gray-500"
                              delete>{formatPrice(item.listed_price)} VND</Text>
                        <Text
                          className="text-base leading-3 font-bold text-red-600">{formatPrice(item.sale_price)} VND</Text>
                      </Row>
                    </>
                  )
                }
              </Space>
            </Col>
          </Row>
        </BoxShadow>
      )}
    </>

  )
}

export default ItemProductList;