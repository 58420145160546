import './style.css';
import './../../assets/css/fontJakarta.css';
import {TopBar} from "../../components/TopBar/TopBar";
export const ProgramRules = () => {
    return (
        <>
            <TopBar title={'THỂ LỆ CHƯƠNG TRÌNH'}/>
            <div className={'container'}>
                <h1>Thể lệ chương trình</h1>
                <div className={'box'}>
                    <h2 className={'title'}>1. Nội dung chương trình</h2>
                    <p className={'content'}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tortor ac leo lorem nisl. Viverra vulputate sodales quis et dui, lacus. Iaculis eu egestas leo egestas vel. Ultrices ut magna nulla facilisi commodo enim, orci feugiat pharetra. Id sagittis, ullamcorper turpis ultrices platea pharetra. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tortor ac leo lorem nisl. Viverra vulputate sodales quis et dui, lacus. Iaculis eu egestas leo egestas vel. Ultrices ut magna nulla facilisi commodo enim, orci feugiat pharetra. Id sagittis, ullamcorper turpis ultrices platea pharetra.
                    </p>
                </div>
                <div className={'box'}>
                    <h2 className={'title'}>2. Thời gian diễn ra chương trình</h2>
                    <p className={'content'}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tortor
                    </p>
                </div>
                <div className={'box'}>
                    <h2 className={'title'}>3. Cách săn sale</h2>
                    <p className={'content'}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tortor ac leo lorem nisl. Viverra vulputate sodales quis et dui, lacus. Iaculis eu egestas leo egestas vel. Ultrices ut magna nulla facilisi commodo enim, orci feugiat pharetra. Id sagittis, ullamcorper turpis ultrices platea pharetra. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tortor ac leo lorem nisl. Viverra vulputate sodales quis et dui, lacus. Iaculis eu egestas leo egestas vel. Ultrices ut magna nulla facilisi commodo enim, orci feugiat pharetra. Id sagittis, ullamcorper turpis ultrices platea pharetra.
                    </p>
                </div>
                <div className={'box'}>
                    <h2 className={'title'}>4. Quy định chung</h2>
                    <p className={'content'}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tortor ac leo lorem nisl. Viverra vulputate sodales quis et dui, lacus. Iaculis eu egestas leo egestas vel. Ultrices ut magna nulla facilisi commodo enim, orci feugiat pharetra. Id sagittis, ullamcorper turpis ultrices platea pharetra. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tortor ac leo lorem nisl. Viverra vulputate sodales quis et dui, lacus. Iaculis eu egestas leo egestas vel. Ultrices ut magna nulla facilisi commodo enim, orci feugiat pharetra. Id sagittis, ullamcorper turpis ultrices platea pharetra.
                    </p>
                </div>
            </div>
        </>
    )
}