import {useEffect, useRef} from "react";

export const Textarea = ({
    name = '', value = false, id = '', defaultValue = '', rows = 1, cols = 30, onChange = () => {
    }, placeholder = ''
}) => {
    const ref = useRef(null);
    const onChangeInput = (e) => {
        if (ref.current) {
            ref.current.style.height = 'inherit';
            ref.current.style.height = `${ref.current.scrollHeight}px`;
        }
    }
    useEffect(() => onChangeInput(''), [])
    return (
        value === false ?
            <textarea
                ref={ref}
                name={name}
                id={id}
                cols={cols}
                rows={rows}
                placeholder={placeholder}
                defaultValue={defaultValue}
                onInput={(e) => onChangeInput(e)}
                onChange={(e) => onChange(e)}
            /> :
            <textarea
                ref={ref}
                name={name}
                id={id}
                cols={cols}
                rows={rows}
                value={value}
                placeholder={placeholder}
                onInput={(e) => onChangeInput(e)}
                onChange={(e) => onChange(e)}
            />
    )

}