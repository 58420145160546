import RoutesMain from "./routes/routes";
import './assets/css/global.css';
import './assets/css/countdown.css';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {ConfigProvider} from "antd";
import {QueryClient, QueryClientProvider} from "react-query";
import Contact from "./components/Contact/Contact";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

export default function App() {
  const queryClient = new QueryClient();
  const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;
  return (
    <>
      <GoogleReCaptchaProvider
        reCaptchaKey={recaptchaKey}
        scriptProps={{
          async: false, // optional, default to false,
          defer: false, // optional, default to false
          appendTo: 'head', // optional, default to "head", can be "head" or "body",
          nonce: undefined, // optional, default undefined
        }}
      >
        <QueryClientProvider client={queryClient}>
          <ConfigProvider
            theme={{
              token: {
                fontFamily: 'Averta Std CY',
              },
            }}
          >
            <RoutesMain/>
            <Contact/>
          </ConfigProvider>
        </QueryClientProvider>
      </GoogleReCaptchaProvider>
    </>
  );
}
