import {ModalBottom} from "../../components/ModalBottom/ModalBottom";
import "./dieu_khoan.css";

const DieuKhoanDichVuDHC = ({openDieuKhoan}) => {
  return (
    <ModalBottom
      positionClose={"topRight"}
      colorClose={"white"}
      isOpen={openDieuKhoan}>
      <div className={"box modal-certificate"}>
        <div className={"box-header"}>
          <div className={"backgorund-linear"}>
            Điều khoản Dịch vụ
          </div>
        </div>
        <div className={"content"} id={'chinh_sach_bao_hanh'} style={{padding: '1rem', overflow: 'auto', maxHeight: 'calc(100vh - 100px)'}}>
          <p align="center" className='mb-2'>
            <strong style={{color: '#2B4063'}}>ĐIỀU KHOẢN DỊCH VỤ</strong>
          </p>
          <p className="mb-1" style={{color: '#0C41A4'}}>
            <strong className="list-index-lv1">A.</strong><strong>ĐIỀU KHOẢN THANH TOÁN</strong>
          </p>
          <p className="mb-1 text-lv1">
            <strong className="list-index-lv1">1.</strong><strong>THANH TOÁN NGAY</strong>
          </p>
          <ul className="thut-lv1">
            <li>Khách hàng thực hiện thanh toán trả trước 100% ngay khi đặt hàng trên App MBBank.</li>
          </ul>
          <p className="mb-1 text-lv1">
            <strong className="list-index-lv1">2.</strong><strong>THANH TOÁN KHI NHẬN HÀNG</strong>
          </p>
          <ul className="thut-lv1">
            <li>Khách hàng thực hiện đặt cọc 10% giá trị đơn hàng ngay khi đặt hàng trên App MBBank.</li>
            <li>Khách hàng thực hiện thanh toán phần còn lại cho người giao hàng để được nhận hàng.</li>
          </ul>
          <p className="mb-1" style={{color: '#0C41A4'}}>
            <strong className="list-index-lv1">B.</strong><strong>CHÍNH SÁCH HỦY</strong><strong>, </strong>
            <strong>ĐỔI TRẢ HÀNG</strong>
          </p>
          <p className="mb-1 text-lv1">
            <strong className="list-index-lv1">1.</strong><strong>CHÍNH SÁCH HỦY HÀNG</strong>
          </p>
          <table border={1} cellSpacing={0} cellPadding={0}>
            <tbody>
            <tr>
              <td width={82} valign="top">
                <p>
                  <strong>Trường hợp</strong>
                </p>
              </td>
              <td width={173} valign="top">
                <p>
                  <strong>
                    Khách hàng tự hủy đơn trên App MBBank trước khi chúng tôi tiếp nhận đơn
                  </strong>
                </p>
              </td>
              <td width={173} valign="top">
                <p>
                  <strong>Khách hàng liên hệ Hotline để hủy đơn trước khi đơn vị vận chuyển đến lấy hàng
                  </strong>
                </p>
              </td>
              <td width={174} valign="top">
                <p>
                  <strong>Khách hàng liên hệ Hotline để hủy đơn sau khi đơn vị vận chuyển đến lấy hàng
                  </strong>
                </p>
              </td>
            </tr>
            <tr>
              <td width={82} valign="top">
                <p>
                  <strong>Giá trị hoàn tiền</strong>
                </p>
              </td>
              <td width={173} valign="top">
                <p>
                  100% giá trị khách hàng đã thanh toán. <strong/>
                </p>
              </td>
              <td width={173} valign="top">
                <p>
                  100% giá trị khách hàng đã thanh toán. <strong/>
                </p>
              </td>
              <td width={174} valign="top">
                <p>
                  100% giá trị khách hàng đã thanh toán trừ phí vận chuyển.
                </p>
              </td>
            </tr>
            <tr>
              <td width={82} valign="top">
                <p>
                  <strong>Thời gian hoàn tiền</strong>
                </p>
              </td>
              <td width={346} colSpan={2} valign="top">
                <p>
                  Tối đa 05 ngày làm việc kể từ khi hủy đơn hàng.
                </p>
              </td>
              <td width={174} valign="top">
                <p>
                  Tối đa 05 ngày làm việc kể từ khi hàng được hoàn về kho thành công.
                </p>
              </td>
            </tr>
            <tr>
              <td width={82} valign="top">
                <p>
                  <strong>Hình thức hoàn tiền</strong>
                </p>
              </td>
              <td width={519} colSpan={3} valign="top">
                <p>
                  Chúng tôi sẽ chuyển khoản vào tài khoản thanh toán mà khách hàng dùng để thanh toán cho đơn hàng đã đặt.
                </p>
              </td>
            </tr>
            </tbody>
          </table>
          <p>
            <strong className="list-index-lv1">1.2</strong><strong>Phương thức Thanh toán khi nhận hàng:</strong>
          </p>
          <p className="thut-lv1">
            Trong mọi trường hợp, nếu khách hàng hủy đơn hàng trước khi thanh toán toàn bộ giá trị đơn hàng, phần đặt cọc sẽ không được hoàn trả.
          </p>
          <p className={'mt-2 text-lv1'}>
            <strong className="list-index-lv1 ">2.</strong><strong>CHÍNH SÁCH TRẢ HÀNG</strong>
          </p>
          <p>
            <strong className="list-index-lv1">2.1</strong> <strong>Trả hàng do yêu cầu của khách hàng</strong>
          </p>
          <ul className="thut-lv1">
            <li>Chúng tôi không hỗ trợ trả hàng khi không phải do lỗi nhà cung cấp.</li>
          </ul>

          <p>
            <strong className="list-index-lv1">2.2</strong> <strong> Đơn vị vận chuyển không liên lạc được với khách hàng</strong>
          </p>
          <p className="thut-lv1">
            Chúng tôi sẽ tiến hành giao hàng trong 03 ca làm việc (mỗi ca 1 buổi), nếu
            người giao hàng không liên lạc được với khách hàng trong 03 ca này, chúng
            tôi sẽ tiến hành lưu kho vận chuyển trong vòng 03 ngày tiếp theo.
          </p>
          <ul className="thut-lv1">
            <li>
              Trong vòng 03 ngày này, nếu khách hàng liên hệ để giao lại thì phí
              giao lại là 10.000 VNĐ/lần.
            </li>
            <li>
              Sau 03 ngày này, nếu khách hàng không liên hệ để giao lại thì chúng
              tôi sẽ mặc định đơn hàng này bị hủy. Chúng tôi sẽ hoàn tiền chỉ khi nào
              khách hàng chủ động liên hệ Hotline 1900 63 68 19 để yêu cầu hoàn tiền.

            </li>
            <li>
              Điều kiện trả hàng, quy trình kiểm tra, thông báo và giải quyết: Như
              quy định ở Điều 4.
            </li>
            <li>
              Giá trị hoàn tiền:
            </li>
            <table border={1} cellSpacing={0} cellPadding={0}>
              <tbody>
              <tr>
                <td width={82} valign="top">
                  <p>
                    <strong>Thanh toán ngay</strong>
                  </p>
                </td>
                <td width={173} valign="top">
                  <p>
                    <strong>
                      Thanh toán khi nhận hàng
                    </strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td width={173} valign="top">
                  <p>
                    100% giá trị khách hàng đã thanh toán trừ phí vận chuyển cho cả 2 chuyến giao hàng và hoàn hàng.<strong/>
                  </p>
                </td>
                <td width={174} valign="top">
                  <p>
                    Không hoàn lại tiền đặt cọc.
                  </p>
                </td>
              </tr>

              </tbody>
            </table>
          </ul>
          <p className={'mt-2 text-lv1'}>
            <strong className="list-index-lv1">3.</strong><strong>CHÍNH SÁCH ĐỔI HÀNG</strong>
          </p>
          <p>
            <strong className="list-index-lv1">3.1</strong><strong>Đổi hàng do lỗi nhà cung cấp</strong>
          </p>
          <ul className="thut-lv1">
            <li>
              Chúng tôi hỗ trợ đổi hàng 01 lần duy nhất trong vòng 03 ngày đầu
              tiên kể từ thời điểm khách hàng nhận hàng thành công trong các trường hợp
              sau:
            </li>
            <ul>
              <li>Sản phẩm được giao cho khách hàng trong tình trạng lỗi hoặc hư hỏng do lỗi của nhà sản xuất hoặc đơn vị vận chuyển.</li>
              <li>Sản phẩm hết hạn sử dụng.</li>
              <li>Sản phẩm được giao không đúng với mô tả sản phẩm mà khách hàng đã đặt.</li>
            </ul>
            <li>
              Việc đổi hàng được hỗ trợ hoàn toàn miễn phí.
            </li>
          </ul>

          <p>
            <strong className="list-index-lv1">3.2</strong><strong> Đổi hàng do yêu cầu của khách hàng (không phải do lỗi nhà cung cấp)
          </strong>
          </p>
          <ul className="thut-lv1">
            <li>Chúng tôi không hỗ trợ đổi hàng kể từ thời điểm đặt hàng.
            </li>
          </ul>
          <p className={'mt-2 text-lv1'}>
            <strong className="list-index-lv1">4.</strong><strong>QUY TRÌNH ĐỔI TRẢ HÀNG SAU KHI KHÁCH HÀNG ĐÃ NHẬN HÀNG
          </strong>
          </p>
          <p>
            <strong className="list-index-lv1">4.1</strong><strong> Điều kiện đổi trả hàng</strong>
          </p>
          <p className="thut-lv1">
            Khách hàng vui lòng liên hệ Hotline 1900 63 68 19 để được hướng dẫn hỗ trợ
            đổi trả hàng và phải đáp ứng tất cả các điều kiện sau đây:
          </p>
          <ul className="thut-lv1">
            <li>
              Khách hàng quay lại video clip quá trình mở kiện hàng lần đầu tiên (video quay liền mạch không đứt đoạn hay cắt ghép thông tin, thể hiện đủ các mặt của hàng hóa vẫn còn được đóng gói cẩn thận, chưa bị bóc hàng hay đóng gói lại, thể hiện rõ hạn sử dụng trên bao bì).
            </li>
            <li>
              Kiện hàng đổi phải bao gồm đầy đủ sản phẩm đã đặt, các quà tặng, phiếu mua hàng, phiếu giảm giá đính kèm (nếu có).
            </li>
            <li>
              Sản phẩm chưa có dấu hiệu sử dụng, nguyên tem niêm phong, chưa khui seal, không bị hư hỏng, trầy xước hoặc có vết dơ trên bao bì sản phẩm.
            </li>
            <li>
              Sản phẩm chỉ dùng cho mục đích sử dụng cá nhân, không áp dụng việc sử dụng sản phẩm cho mục đích thương mại.
            </li>
          </ul>
          <p>
            <strong className="list-index-lv1">4.2</strong><strong> Hướng dẫn khách hàng thực hiện thủ tục
          </strong>
          </p>
          <p className="thut-lv1">
            <strong><em>Bước 1: Kiểm tra điều kiện đổi trả hàng</em></strong>
            <br/>
            <span>Khách hàng tự kiểm tra đơn hàng đáp ứng các điều kiện trả hàng như quy định.</span>
          </p>
          <p className="thut-lv1">
            <strong><em>Bước 2: Đăng ký đổi trả</em></strong>
            <br/>
            <span>Khách hàng vui lòng liên hệ Hotline 1900 63 68 19 để cung cấp những thông tin theo yêu cầu để được hỗ trợ đổi trả.</span>
          </p>
          <p className="thut-lv1">
            <strong><em>Bước 3: Đóng gói sản phẩm và gửi hàng</em></strong>
            <br/>
            <span>
              1. Sản phẩm yêu cầu đổi hoặc trả cần được đóng gói cẩn thận, bên trong bao gồm:</span>
          </p>
          <ul className="thut-lv2">
            <li>
              Sản phẩm muốn đổi
            </li>
            <li>
              Đầy đủ hộp và các quà tặng, phiếu mua hàng, phiếu giảm giá (nếu có)
            </li>
            <li>
              Mút xốp, bóng khí (nếu có) để đảm bảo hàng nguyên vẹn khi vận chuyển.
            </li>
          </ul>
          <p className="thut-lv1">
            2. Bên ngoài kiện hàng, khách hàng cung cấp đầy đủ thông tin như sau:
          </p>
          <ul className="thut-lv2">
            <li>
              Đổi sản phẩm (nêu lý do)
            </li>
            <li>
              Họ tên người đặt hàng
            </li>
            <li>
              Số điện thoại người đặt hàng
            </li>
            <li>
              Mã đơn hàng
            </li>
            <li>
              Mã vận đơn
            </li>
          </ul>
          <p className="thut-lv1">
            3. Khách hàng cung cấp video quay lại quá trình mở sản phẩm lần đầu tiên
            và video quay lại quá trình đóng gói sản phẩm theo hướng dẫn của nhân viên
            Chăm sóc khách hàng.
          </p>
          <p className="thut-lv1">
            4. Giao kiện hàng cho đơn vị vận chuyển.
          </p>
          <p>
            <strong className="list-index-lv1">4.3</strong><strong> Kiểm tra, thông báo và giải quyết</strong>
            <strong/>
          </p>
          <ul className="thut-lv1">
            <li>Ngay khi nhận được sản phẩm đổi hoặc trả của khách hàng, chúng tôi
              tiến hành đánh giá sản phẩm và thông báo kết quả cùng phương hướng giải
              quyết đến khách hàng trong vòng tối đa 03 ngày làm việc.
            </li>
            <li>Nếu khách hàng đáp ứng đủ các điều kiện nêu trên, chúng tôi sẽ tiến
              hành hoàn tiền cho khách hàng trong vòng tối đa 05 ngày làm việc tiếp theo
              (nếu phát sinh).
            </li>
            <li>
              Nếu sản phẩm được hoàn trả bởi khách hàng không đáp ứng được các
              điều kiện nêu trên, chúng tôi sẽ liên hệ và giao lại sản phẩm đến địa chỉ
              khách hàng cung cấp. Khách hàng sẽ chịu phí vận chuyển cho
              chuyến giao này. <strong/>
            </li>
          </ul>
          <p className={'text-center'}>
            <strong>Chúc khách hàng có trải nghiệm mua sắm các sản phẩm DHC thật tuyệt vời tại App MBBank!</strong>
          </p>
        </div>
      </div>
    </ModalBottom>
  )
}
export default DieuKhoanDichVuDHC;