import "./style.css";
import {useEffect, useRef, useState} from "react";
import modal_icon_close from "./../../assets/images/modal_icon_close.png";

const styleIconClose = {
    topLeft: {
        top: "2px",
        left: "10px",
    },
    topRight: {
        top: "2px",
        right: "10px",
    },
};

export const ModalBottom = ({
    isOpen = false,
    onClose = null,
    children = "",
    positionClose = "topRight",
    colorClose = "black",
    background = "transparent",
    closeIconX = false,
}) => {
    const ref1 = useRef();
    const ref2 = useRef();
    const [isOpenModal, setIsOpenModal] = useState(null);
    useEffect(() => {
        if (ref1.current && ref2.current) {
            if (isOpenModal) {
                ref1?.current?.classList?.add('open');
                setTimeout(() => {
                    ref2.current?.classList?.add('open');
                }, 80);
            } else if (!isOpenModal) {
                ref2.current?.classList?.remove('open');
                setTimeout(() => {
                    ref1.current?.classList?.remove('open');
                }, 200);
            }
        }
    }, [isOpenModal])

    useEffect(() => {
        setIsOpenModal(prevProp => prevProp === null ? false : !prevProp);
    }, [isOpen])

    const onClickClose = () => {
        if (onClose) onClose();
        setIsOpenModal(!isOpenModal);
    };
    return (
        <div className={"modal-bottom"} ref={ref1}>
            <div className={"modal--background"} onClick={onClickClose}></div>
            <div className={"modal--content"} ref={ref2}>
                <div
                    className={"modal--box_content"}
                    style={{background: background}}
                >
                    <div
                        className={"modal--icon_close"}
                        onClick={onClickClose}
                        style={styleIconClose[positionClose] ?? styleIconClose.topRight}
                    >
                        {!closeIconX && (
                            <img
                                className={colorClose}
                                src={modal_icon_close}
                                width={13}
                                height={13}
                                alt=""
                            />
                        )}
                    </div>
                    <div className={"content-childrend"}>{children}</div>
                </div>
            </div>
        </div>
    );
};
